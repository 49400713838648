<script lang="ts" setup>
import type { LabelValue } from "@/types/form";

const props = defineProps<{
  modelValue: string | number;
  items: Array<LabelValue>;
  disabled?: boolean;
}>();

const emit = defineEmits<{
  (e: "update:modelValue", value: string): void;
}>();
const modelVal = computed({
  get: () =>
    props.items.reduce(
      (accumulator, item) => {
        return item.value === String(props.modelValue) ? item : accumulator;
      },
      {
        label: "",
        value: "",
      }
    ),
  set: (item) => {
    emit("update:modelValue", item.value as string);
  },
});
</script>

<template>
  <FieldCombobox v-model="modelVal" :items="items" :disabled="disabled" />
</template>
